<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'SFTP Upload'" />

    <div>
      <h4 class="pb-4">File Upload</h4>

      <v-select
        v-model="sftp.dest"
        :items="folderOptions"
        item-text="name"
        item-value="value"
        :rules="[(v) => !!v || 'Mohon pilih direktori']"
        label="Direktori"
        color="primary"
        outlined
        dense
        required
      ></v-select>

      <VueFileAgent
        v-if="sftp.dest === '/PKDigital'"
        :theme="'list'"
        :deletable="true"
        :accept="
          'zip,application/octet-stream, application/zip,application/x-zip, application/x-zip-compressed, .rar, application/pdf'
        "
        :helpText="'Pilih file yang ingin diupload'"
        v-model="sftp.file"
        @beforedelete="deleteFile($event)"
        ref="sftpFile"
      ></VueFileAgent>

      <VueFileAgent
        v-else
        :theme="'list'"
        :deletable="true"
        :accept="
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        "
        :helpText="'Pilih file yang ingin diupload'"
        v-model="sftp.file"
        @beforedelete="deleteFile($event)"
        ref="sftpFile"
      ></VueFileAgent>

      <div class="py-4" />

      <button-form
        :submit="submitFile"
        :buttonText="'Unggah'"
        :loading="loading"
        class="pt-3"
      />
    </div>

    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error
      :dialog="showErrDialog"
      :closeDialog="closeErrDialog"
      :errorMessage="errorMsg"
    />
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import store from "../../store/index";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";

export default {
  components: {
    HeaderContent,
    ButtonForm,
    PopupSuccess,
    PopupError,
  },

  data() {
    return {
      steps: 1,
      showSuccessDialog: false,
      showErrDialog: false,
      errorMsg: "",
      loading: false,
      sftp: {
        dest: "",
        file: "",
      },
      folderOptions: [],
    };
  },

  mounted() {
    store.dispatch("bniGfx/fetchSFTPData", ["", false]);
  },

  watch: {
    dataWeb(newValue, oldValue) {
      let baru = newValue;
      if (newValue) {
        let filterBaru = baru.filter((x) => x.type == "folder");
        let filters = [];
        filterBaru.forEach((element) => {
          filters.push({ value: "/" + element.name, name: element.name });
        });
        this.folderOptions = filters;
      }
    },
  },

  computed: {
    ...mapState("bniGfx", ["dataWeb"]),
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
    deleteFile(fileRecord) {
      this.$refs.sftpFile.deleteFileRecord(fileRecord);
      this.sftp.file = "";
    },
    async submitFile() {
      if (this.sftp.file && this.sftp.dest) {
        this.loading = true;
        let payload = new FormData();
        payload.set("dest", this.sftp.dest);
        payload.append("file", this.sftp.file.file);
        await store.dispatch("bniGfx/uploadFileSFTP", payload).then((r) => {
          if (r === "success") {
            this.showSuccessDialog = !this.showSuccessDialog;
            this.loading = false;
          } else {
            this.showErrDialog = true;
            this.loading = false;
          }
        });
      } else {
        this.showErrDialog = true;
      }
    },
  },
};
</script>
